@import "_reset";
@import "_variables";
@import "_common";
@import "../../node_modules/@splidejs/splide/dist/css/splide.min.css";

/* SITE SPECIFIC
---------------------------------------------- */

@media (hover: hover) {
  .underlined:hover {
    text-decoration-color: transparent;
  }
}

.underlined {
  text-decoration: underline;
  text-underline-position: intial;
  text-decoration-thickness: 0.08em;
  transition: text-decoration-color 0.4s ease;
}

@media (hover: hover) {
  .underline-on-hover:hover,
  .navbar__button:hover span {
    text-decoration-color: inherit;
  }
}

.underline-on-hover,
.navbar__button span {
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-position: intial;
  text-decoration-thickness: 0.1em;
  transition: text-decoration-color 0.4s ease;
}

/* *:focus {
  outline: none;
} */
a:focus,
button:focus {
  outline: none;
}
button:focus-visible,
a:focus-visible {
  outline: 2px solid var(--text-color);
  outline-offset: 3px;
}
/* a:focus-visible {
  background: inherit;
} */
.splide {
  visibility: inherit;
}
.splide.is-initialized,
.splide.is-rendered {
  visibility: inherit;
}
.splide__slide {
  align-self: start;
}
.splide__track {
  height: 100%;
}
.splide__track--nav > .splide__list > .splide__slide.is-active,
.splide__track--nav > .splide__list > .splide__slide {
  border: none;
}
.read-more {
  font-weight: 700;
}
.heading-font {
  font-family: var(--heading-font);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--heading-letter-spacing);
  font-size: var(--primary-font-size);
}
.secondary-font {
  font-family: var(--secondary-font);
  font-weight: var(--secondary-font-weight);
  letter-spacing: var(--secondary-letter-spacing);
  font-style: var(--secondary-font-style);
  font-size: var(--secondary-large-font-size);
  line-height: 1.2;
}
.rte h2 {
  margin: 1em 0;
  margin-bottom: 0.8em;
  font-family: var(--secondary-font);
  font-size: 1.2em;
}
.block-type-logo.rte {
  margin: 1rem 0;
}
.block-type-logo.rte img {
  width: 150px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 1rem;
}
.return-footer {
  font-weight: 700;
  text-align: right;
  padding: var(--lr-page-padding);
}
.pill {
  border: var(--border-width) solid var(--text-color);
  padding: 0.3em 4em;
  text-align: center;
  border-radius: 9px;
  font-weight: 700;
  text-transform: uppercase;
  transition: color 0.2s ease-out, background-color 0.2s ease-out;
  font-size: var(--small-font-size);
}
@media (max-width: 1000px) {
  .pill {
    padding: 0.3em 2em;
  }
}
.pill--short {
  padding-left: 1em;
  padding-right: 1em;
}
.pill--tall {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}
.pill--dark {
  background-color: var(--text-color);
  color: var(--text-accent-color);
}
@media (hover: hover) {
  .pill:hover {
    background-color: var(--text-color);
    color: var(--text-accent-color);
  }
  .pill--dark:hover {
    background-color: initial;
    color: var(--text-color);
  }
}
.fullwidth-input {
  width: 100%;
}
.email-form__label {
  display: block;
  font-weight: 700;
  margin-bottom: var(--extra-small);
}
.email-form__field {
  margin-bottom: var(--small);
}
.email-form input:not([type="checkbox"]),
.email-form textarea,
.email-form input:not([type="checkbox"]):required,
.email-form select:required {
  border: var(--border-width) solid var(--text-color);
  border-radius: 9px;
  padding: var(--extra-small);
  background: transparent !important;
  background-position: initial !important;
  background-size: initial !important;
  background-repeat: initial !important;
  background-image: none !important;
  width: 100%;
  margin-top: var(--extra-small);
  margin-bottom: var(--small);
}
.email-form button {
  display: block;
  font-weight: 700;
  margin-top: var(--medium);
  margin-bottom: var(--extra-small);
  background-color: var(--primary-accent-background-color);
  color: var(--text-accent-color);
  padding: 0.8em 2.5em;
  border-radius: 9px;
  transition: background-color 0.2s ease-out;
}
@media (hover: hover) {
  .email-form button:hover {
    background-color: var(--text-color);
  }
}
.checkbox {
  width: 14px;
  height: 14px;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  border: var(--border-width) solid var(--text-color);
}
@media (hover: hover) {
  .checkbox:hover {
    cursor: pointer;
  }
}
.checkbox:checked {
  background-color: var(--primary-accent-background-color);
}
.checkbox::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  width: 6px;
  height: 12px;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkbox:checked::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  width: 6px;
  height: 12px;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
summary:hover {
  cursor: pointer;
}
/* NAVBAR
---------------------------------------------- */
.navbar {
  position: fixed;
  z-index: 10;
  background-color: var(--primary-background-color);
  display: grid;
  grid-template-columns: 1fr 200px 1fr;
  width: 100%;
  align-items: center;
  padding-left: calc(var(--lr-page-padding) / 2);
  padding-right: calc(var(--lr-page-padding) / 2);
}
.navbar--dark {
  background-color: var(--text-color);
  color: var(--text-accent-color);
}
.navbar--dark svg path {
  fill: var(--text-accent-color) !important;
}
.navbar__left {
  display: flex;
  margin-right: auto;
}
.navbar__center {
  align-self: center;
  margin: 0 auto;
  position: relative;
}
.navbar__dates {
  position: absolute;
  top: 0px;
  text-align: center;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -300%);
  transition: transform 0.3s ease-out;
}
@media (max-width: 1000px) {
  .navbar__dates {
    width: 220px;
  }
}
.in-view {
  transform: translate(-50%, -50%);
}
.navbar__right {
  display: flex;
  margin-left: auto;
}
.navbar__right .navbar__button a {
  display: flex;
  gap: 5px;
}
.navbar__button a,
.menu-button {
  padding: calc(var(--lr-page-padding) / 2);
  text-align: center;
  display: block;
}

.navbar__logo {
  padding: 11px;
  display: block;
  overflow: hidden;
}
.navbar__logo--absolute {
  position: absolute;
  height: 36px;
}
.navbar__logo img {
  height: 36px;
  width: auto;
}
.navbar__logo--home .navbar__logo-image {
  transform: translateY(100px);
  transition: transform 0.3s ease-out;
}

.navbar__logo--home .navbar__logo-image.in-view {
  transform: initial;
}

/* FOOTER
---------------------------------------------- */
.footer__container {
  background-color: var(--secondary-background-color);
  padding-top: 185px;
  padding-left: calc(var(--lr-page-padding) / 2);
  padding-right: calc(var(--lr-page-padding) / 2);
  padding-bottom: 33px;
  display: flex;
  gap: var(--gap);
  color: var(--text-accent-color);
  align-items: end;
}
@media (max-width: 1000px) {
  .footer__container {
    flex-direction: column-reverse;
    align-items: initial;
    padding-top: 145px;
  }
  .footer__right {
    margin-bottom: var(--huge);
  }
}

@media (min-width: 1001px) {
  .footer__left {
    width: 50%;
  }
  .footer__right {
    width: 50%;
  }
}

.footer__newsletter {
  padding-left: var(--lr-page-padding);
  margin-bottom: var(--huge);
}
@media (max-width: 1000px) {
  .footer__newsletter {
    padding: 0 calc(var(--lr-page-padding) / 2);
  }
}
.footer__newsletter-heading {
  font-weight: 700;
  margin-bottom: var(--medium);
}

.footer__newsletter input:not([type="checkbox"]):required,
.footer__newsletter select:required,
.footer__newsletter textarea:required .ft_required {
  background-image: initial !important;
  background: initial !important;
  background-position: initial !important;
  background-size: initial !important;
  background-repeat: initial !important;
}

.newsletter-inputs input {
  border: var(--border-width) solid var(--text-accent-color);
  color: var(--text-accent-color);
  padding: 0.8rem 0.8rem;
  border-radius: 9px;
  text-transform: uppercase;
}

.newsletter-inputs input.email {
  width: calc(100% - 130px);
}
@media (max-width: 1000px) {
  .newsletter-inputs input.email {
    width: calc(100% - 120px);
  }
}

.newsletter-inputs input.email::placeholder {
  color: var(--text-accent-color);
}

.newsletter-inputs input.subscribe {
  width: 115px;
  background-color: transparent;
  font-weight: 700;
  text-transform: uppercase;
  transition: background-color 0.4s ease-out, color 0.4s ease-out;
}
@media (hover: hover) {
  .newsletter-inputs input.subscribe:hover {
    color: var(--secondary-background-color);
    background-color: var(--text-accent-color);
    cursor: pointer;
  }
}
.acknowledgement {
  padding-left: calc(var(--lr-page-padding) / 2);
  padding-right: calc(var(--lr-page-padding) / 2);
}

@media (min-width: 1001px) {
  .acknowledgement {
    max-width: 550px;
  }
}

.website-credits {
  margin-top: 1em;
  padding-left: calc(var(--lr-page-padding) / 2);
  padding-right: calc(var(--lr-page-padding) / 2);
}

.footer__social-links {
  padding-left: calc(var(--lr-page-padding) / 2);
  padding-right: calc(var(--lr-page-padding) / 2);
  font-family: var(--secondary-font);
  font-size: var(--secondary-large-font-size);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 1000px) {
  .footer__social-links {
    padding: 0 0;
  }
}
.footer__social-link {
  padding: 0 calc(var(--lr-page-padding) / 2);
  display: block;
}

/* HOME
---------------------------------------------- */
.home {
  padding-top: 60px;
}
.home-banner img {
  /* border-radius: 11px; */
  background-color: var(--secondary-background-color);
}
.home-large-text {
  max-width: 800px;
  margin-top: var(--huge);
}
@media (max-width: 600px) {
  .home-large-text {
    margin-top: var(--large);
  }
}
.home-buttons {
  display: flex;
  gap: var(--gap);
  margin-top: var(--huge);
}
@media (max-width: 600px) {
  .home-buttons {
    margin-top: var(--extra-large);
    flex-direction: column;
  }
}
.home-buttons__button.pill {
  flex-grow: 1;
  border: initial;
  text-align: center;
  border-radius: 9px;
  font-weight: 700;
  text-transform: uppercase;
  transition: color 0.2s ease-out, background-color 0.2s ease-out;
  font-size: 1rem;
  background-color: var(--primary-accent-background-color);
  color: var(--text-accent-color);
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-buttons__button.pill span {
  display: block;
}
.home-buttons__button.pill:hover {
  background-color: var(--text-color);
  color: var(--text-accent-color);
}
.home-trailer {
  margin-top: var(--huge);
  position: relative;
}
@media (max-width: 600px) {
  .home-trailer {
    margin-top: var(--extra-large);
  }
}
.home-trailer video {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 9px;
}
.controls {
  position: absolute;
  right: var(--lr-page-padding);
  bottom: var(--lr-page-padding);
  display: flex;
  gap: calc(var(--gap) / 2);
}
.video-control {
  position: relative;
  text-align: center;
}
.video-control button {
  padding: 0.8em calc(var(--lr-page-padding) / 2);
  background-color: var(--primary-accent-background-color);
  color: var(--text-accent-color);
  width: 80px;
  border-radius: 6px;
  text-transform: uppercase;
  transition: color 0.2s ease-out, background-color 0.2s ease-out;
  font-size: var(--small-font-size);
}
@media (hover: hover) {
  .video-control button:hover {
    background-color: var(--secondary-background-color);
  }
}
.home-highlights {
  display: flex;
  gap: var(--gap);
  margin-top: var(--huge);
}
@media (max-width: 1000px) {
  .home-highlights {
    flex-direction: column;
  }
}
.home-highlight {
  flex-grow: 1;
  min-height: 445px;
  background-color: var(--secondary-background-color);
  color: var(--text-accent-color);
  padding: var(--lr-page-padding);
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .home-highlight {
    min-height: 225px;
  }
}
.home-highlight:nth-of-type(2) {
  background-color: var(--primary-accent-background-color);
}
.home-highlight:nth-of-type(3) {
  background-color: var(--text-color);
}
.home-highlight__top,
.home-highlight__bottom {
  max-width: 337px;
}
@media (hover: hover) {
  .home-highlight:hover {
    cursor: pointer;
  }
}
.home-highlight__link {
  margin-top: var(--large);
}
.home-film-passes {
  margin-top: var(--huge);
}
.home-film-passes__text {
  max-width: 700px;
  margin-top: var(--medium);
  font-weight: 700;
}
.home-film-passes .multipasses {
  margin-top: var(--extra-large);
}
.home-film-passes .multipass-ctas {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 1000px) {
  .home-film-passes .multipass-ctas {
    grid-template-columns: 1fr;
  }
}
.home-film-passes .multipass-cta {
  flex-grow: 1;
  flex-direction: row;
  gap: var(--large-gap);
  min-height: initial;
}
.home-film-passes .multipass-cta__header {
  flex-grow: 0;
}
.home-film-passes .multipass-cta__footer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  min-width: 200px;
}
.home-film-passes .multipass-cta__byline {
  margin-bottom: var(--small);
}
.home-partners {
  margin-top: var(--huge);
}
.home-partners .partners__grid {
  margin-top: var(--extra-large);
}
.home-artist {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap);
  margin-top: var(--huge);
}
@media (max-width: 1000px) {
  .home-artist {
    grid-template-columns: 1fr;
  }
  .home-artist__info {
    min-height: 340px;
  }
}
.home-artist__info {
  border-radius: 9px;
  background-color: var(--secondary-background-color);
  color: var(--text-accent-color);
  padding: var(--lr-page-padding);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home-artist__text {
  max-width: 337px;
}
.home-artist__link {
  display: inline-block;
  margin-top: var(--large);
  background-color: var(--text-accent-color);
  color: var(--secondary-background-color);
  border: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.home-donate {
  margin-top: var(--extra-large);
}
.home-artist__image img {
  border-radius: 9px;
}
/* STANDARD PAGE
---------------------------------------------- */
.standard-page {
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
  margin-bottom: var(--page-bottom);
}
.standard-page--small-bottom-margin {
  margin-bottom: var(--lr-page-padding);
}
.content {
  display: grid;
}
.content--1-column {
  grid-template-columns: calc(var(--sidebar-width) + var(--large-gap)) 1fr calc(
      var(--sidebar-width) + var(--large-gap)
    );
}
@media (max-width: 1200px) {
  .content--1-column {
    grid-template-columns: calc(var(--sidebar-width) + var(--large-gap)) 1fr;
  }
}
@media (max-width: 1000px) {
  .content--1-column {
    grid-template-columns: 1fr;
  }
}
.content--2-columns {
  grid-template-columns: calc(var(--sidebar-width) + var(--large-gap)) 1fr;
}
@media (max-width: 1000px) {
  .content--2-columns {
    grid-template-columns: 1fr;
  }
}
.content--3-columns {
  grid-template-columns: calc(var(--sidebar-width) + var(--large-gap)) 1fr calc(
      var(--sidebar-width) + var(--large-gap)
    );
  /* gap: var(--large-gap); */
}
@media (max-width: 1000px) {
  .content--3-columns {
    grid-template-columns: 1fr;
  }
}

.offset-content--centered {
  /* margin-left: var(--sidebar-width);
  margin-right: var(--sidebar-width); */
}
@media (max-width: 1400px) {
  /* .offset-content--centered {
    margin-left: var(--sidebar-width);
    margin-right: initial;
  } */
}
.centered-item {
  max-width: 600px;
}
@media (min-width: 1401px) {
  .centered-item {
    margin-left: auto;
    margin-right: auto;
  }
}
/* SIDEBAR
---------------------------------------------- */
.sidebar {
  width: calc(var(--sidebar-width) + var(--large-gap));
  position: sticky;
  top: var(--top-page-offset);
  padding-right: var(--large-gap);
}
@media (max-width: 1000px) {
  .sidebar {
    display: flex;
    flex-direction: column-reverse;
    position: initial;
    top: initial;
    margin-top: var(--navbar-height);
    width: calc(100vw - calc(var(--lr-page-padding) * 2));
    padding-right: initial;
  }
}
@media (min-width: 1001px) {
  .sidebar__nav {
    padding-top: var(--large);
  }
}
@media (max-width: 1000px) {
  .sidebar__nav {
    /* width: 100%; */
    margin-right: calc(var(--lr-page-padding) * -1);

    overflow: auto;
    white-space: nowrap;
    border-top: var(--border-width) solid var(--text-color);
    border-bottom: var(--border-width) solid var(--text-color);
    display: flex;
    gap: var(--large);
    margin-bottom: var(--extra-large);
    position: relative;
  }
}
.sidebar__link {
  display: block;
  font-weight: 700;
  padding: var(--extra-small) 0;
  border-bottom: var(--border-width) solid var(--text-color);
  max-width: 278px;
}
@media (max-width: 1000px) {
  .sidebar__link {
    display: inline-block;
    padding: var(--medium) 0;
    border-bottom: initial;
  }
}
@media (min-width: 1001px) {
  .sidebar__link:first-of-type {
    border-top: var(--border-width) solid var(--text-color);
  }
}

/* .sidebar__link-text {
  transition: padding-left 0.2s ease-in-out;
}
.sidebar__link:hover .sidebar__link-text {
  padding-left: 0.5em;
} */

/* MERCHANDISE
---------------------------------------------- */
.products {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: var(--large-gap);
  row-gap: calc(var(--large-gap) * 1.5);
  padding-top: var(--secondary-top-page-offset);
}
@media screen and (max-width: 1000px) {
  .products {
    padding-top: var(--mobile-content-offset);
    grid-template-columns: 1fr;
    row-gap: var(--large-gap);
  }
}
.products-card__title {
  font-size: var(--secondary-small-font-size);
  max-width: 400px;
}
.products-card__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 6px;
}
.products-card__footer {
  margin-top: var(--large);
  margin-bottom: var(--medium);
  border-bottom: var(--border-width) solid var(--text-color);
  padding-bottom: var(--extra-small);
  display: flex;
  justify-content: space-between;
  align-items: end;
}

/* PRODUCTS
---------------------------------------------- */
.product {
  display: grid;
  grid-template-columns: 1fr 1.8fr 1fr;
  gap: calc(var(--large-gap) * 2);
  /* align-items: end;
  align-content: end; */
  padding-top: var(--top-page-offset);
}
@media screen and (max-width: 1000px) {
  .product {
    padding-top: var(--mobile-content-offset);
    grid-template-columns: 1fr;
    gap: var(--large-gap);
  }
  .product-carousel-wrapper {
    order: -1;
  }
}
.product-details__title {
  font-size: var(--secondary-medium-font-size);
}
.product-details__price {
  font-weight: 700;
  margin-top: var(--extra-small);
}
.product-details__description {
  margin-top: var(--large);
}
.product-widget-container {
  margin-top: var(--large);
}
@media screen and (min-width: 1001px) {
  .product-image {
    position: sticky;
    top: var(--navbar-height);
  }
}

.product-image img {
  border-radius: 6px;
}
/* INITIATIVES
---------------------------------------------- */
.initiatives-card__image img {
  aspect-ratio: 1.5;
  object-fit: cover;
  border-radius: 6px;
}

.initiatives-articles {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: var(--large-gap);
  margin-top: var(--secondary-top-page-offset);
}
@media screen and (max-width: 1000px) {
  .initiatives-articles {
    margin-top: var(--mobile-content-offset);
    grid-template-columns: 1fr;
  }
}
.initiatives-card__title {
  font-size: var(--secondary-small-font-size);
  margin-top: var(--large);
  margin-bottom: var(--medium);
  max-width: 400px;
}

.initiatives-card__excerpt {
  margin-bottom: var(--medium);
  max-width: 400px;
}

/* INITIATIVE
---------------------------------------------- */
.initiative-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap);
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
  padding-top: var(--top-page-offset);
  margin-bottom: var(--gap);
}
@media screen and (max-width: 1000px) {
  .initiative-header {
    grid-template-columns: 1fr;
  }
}
.initiative-header__left {
  border-radius: 9px;
  background-color: var(--secondary-background-color);
  color: var(--text-accent-color);
  padding: var(--lr-page-padding);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.initiative-title {
  max-width: 550px;
  padding-bottom: 1em;
}
.initiative-excerpt {
  max-width: 350px;
}
.initiative-image img {
  border-radius: 9px;
}
.initiative-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
  gap: var(--gap);
  margin-bottom: var(--page-bottom);
}
@media screen and (max-width: 1000px) {
  .initiative-content {
    display: flex;
    flex-direction: column-reverse;
  }
  .initiative-content__blocks {
    margin-top: var(--small);
  }
}
.initiative-content__link {
  margin-left: auto;
}
@media screen and (max-width: 1000px) {
  .initiative-content__link {
    margin-left: initial;
  }
  .initiative-content__link a {
    display: block;
  }
}

/* NEWS
---------------------------------------------- */
.news-articles {
  padding-top: var(--secondary-top-page-offset);
}
@media screen and (max-width: 1000px) {
  .news-articles {
    padding-top: var(--mobile-content-offset);
  }
}

.news-card {
  text-align: center;
  align-items: center;
  margin-bottom: var(--large-gap);
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: var(--large-gap);
}

@media screen and (max-width: 1000px) {
  .news-card {
    grid-template-columns: 1fr;
    text-align: initial;
  }
}

.news-card__image img {
  border-radius: 6px;
}

.news-card__title {
  font-size: var(--secondary-small-font-size);
  margin-top: var(--small);
  margin-bottom: var(--medium);
}

.news-card__excerpt {
  margin-bottom: var(--medium);
}

.article-date {
  font-weight: 700;
}

/* ARTICLE
---------------------------------------------- */
.article-header {
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
  padding-top: var(--top-page-offset);
  text-align: center;
}
.article-image img {
  border-radius: 11px;
  max-width: 882px;
  margin: 0 auto;
  margin-bottom: var(--large);
}
.article-title {
  max-width: 600px;
  margin: 0 auto;
  margin-top: var(--small);
  margin-bottom: var(--large-gap);
}
.article-content {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: var(--page-bottom);
}
@media screen and (max-width: 1000px) {
  .article-content {
    padding-left: var(--lr-page-padding);
    padding-right: var(--lr-page-padding);
  }
}

/* SEARCH
---------------------------------------------- */
.search-header {
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
  padding-top: var(--top-page-offset);
  text-align: center;
}
.search-title {
  max-width: 600px;
  margin: 0 auto;
  margin-top: var(--small);
  margin-bottom: var(--medium);
}
.search-note {
  margin-top: var(--extra-large);
  margin-bottom: var(--extra-large);
}
.search-form__input {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  border-bottom: var(--border-width) solid var(--text-color);
  margin-right: var(--small);
}
@media screen and (min-width: 1000px) {
  .search-form__input {
    width: 300px;
  }
}
.search-form__input:focus {
  outline-color: var(--text-color);
  outline-offset: 2px;
}
input.search-form__input::-webkit-input-placeholder {
  color: var(--text-color) !important;
  opacity: 1 !important;
}
input.search-form__input::-moz-placeholder {
  color: var(--text-color) !important;
  opacity: 1 !important;
}
input.search-form__input:-ms-input-placeholder {
  color: var(--text-color) !important;
  opacity: 1 !important;
}
input.search-form__input:-moz-placeholder {
  color: var(--text-color) !important;
  opacity: 1 !important;
}
@media (hover: hover) {
  .search-form__submit:hover {
    cursor: pointer;
  }
}

.search-content {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: var(--page-bottom);
  min-height: 40vh;
}
@media screen and (max-width: 1000px) {
  .search-content {
    padding-left: var(--lr-page-padding);
    padding-right: var(--lr-page-padding);
  }
}
.search-result__links {
  margin-top: var(--medium);
  display: flex;
  flex-wrap: wrap;
  gap: var(--small);
}
.search-result .screening-description {
  margin-top: var(--large);
}
.short__top-right {
  max-width: 385px;
}
.search__no-result {
  margin-top: var(--extra-large);
  text-align: center;
}
/* TEAM
---------------------------------------------- */
.team {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--large-gap);
  row-gap: calc(var(--large-gap) * 2);
  margin-top: var(--secondary-top-page-offset);
}
@media screen and (max-width: 1000px) {
  .team {
    margin-top: var(--mobile-content-offset);
    grid-template-columns: 1fr 1fr;
  }
}
.person__role {
  font-size: var(--small-font-size);
  font-weight: 700;
  margin-bottom: var(--extra-small);
}
.person__title {
  font-size: var(--secondary-small-font-size);
}
.person .pill {
  display: inline-block;
  margin-top: var(--small);
  padding-left: 2em;
  padding-right: 2em;
}
/* ARCHIVE ITEMS
---------------------------------------------- */
.archive-items {
  margin-top: var(--secondary-top-page-offset);
  max-width: 1280px;
  columns: 2 auto;
  column-gap: 1rem;
}
@media screen and (max-width: 1000px) {
  .archive-items {
    columns: 1 auto;
    margin-top: var(--mobile-content-offset);
  }
}
.archive-item {
  display: inline-block; /* old fix: stops block being split across columns. Could use break-inside: avoid for modern browsers; */
  width: 100%;
  break-inside: avoid;
  border: var(--border-width) solid var(--text-color);
  border-radius: 9px;
  padding: calc(var(--lr-page-padding) * 2);
  padding-bottom: var(--lr-page-padding);
  margin-bottom: var(--lr-page-padding);
}
.archive-item__footer {
  display: flex;
  justify-content: space-between;
  font-size: var(--small-font-size);
  text-transform: uppercase;
  font-weight: 700;
  margin-top: var(--lr-page-padding);
}

/* FAQS
---------------------------------------------- */
.faqs {
  margin-top: var(--secondary-top-page-offset);
}
@media screen and (max-width: 1000px) {
  .faqs {
    margin-top: var(--mobile-content-offset);
  }
}
.faqs__category {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: var(--small);
  margin-top: var(--large);
}
.faqs__accordion {
  border-bottom: var(--border-width) solid var(--text-color);
}
.faqs__question {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 100%;
  /* display: flex; */
  justify-content: space-between;
}
@media (hover: hover) {
  .faqs__question:hover {
    cursor: pointer;
  }
}
.faqs__answer {
  padding-top: 0.5em;
  padding-bottom: 1em;
}

/* .faqs__accordion > summary {
  list-style-type: none;
}

.faqs__accordion > summary::-webkit-details-marker {
  display: none;
}

.faqs__accordion > summary::after {
  content: "";
  width: 14px;
  height: 14px;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.3536 7.35355C13.5488 7.15829 13.5488 6.84171 13.3536 6.64645L10.1716 3.46447C9.97631 3.2692 9.65973 3.2692 9.46447 3.46447C9.2692 3.65973 9.2692 3.97631 9.46447 4.17157L12.2929 7L9.46447 9.82843C9.2692 10.0237 9.2692 10.3403 9.46447 10.5355C9.65973 10.7308 9.97631 10.7308 10.1716 10.5355L13.3536 7.35355ZM1 7.5L13 7.5V6.5L1 6.5V7.5Z' fill='black'/%3E%3C/svg%3E%0A");
}

.faqs__accordion[open] > summary::after {
  content: "🔽";
} */

/* VENUES
---------------------------------------------- */

.venue-items {
  margin-top: var(--secondary-top-page-offset);
}
@media screen and (max-width: 1000px) {
  .venue-items {
    margin-top: var(--mobile-content-offset);
  }
}
.venue {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--large-gap);
}
@media screen and (max-width: 1000px) {
  .venue {
    grid-template-columns: 1fr;
    padding-bottom: var(--extra-large);
    border-bottom: var(--border-width) solid var(--text-color);
    gap: var(--gap);
  }
}
.venue:not(:first-of-type) {
  margin-top: var(--lr-page-padding);
}
@media screen and (max-width: 1000px) {
  .venue:not(:first-of-type) {
    margin-top: var(--extra-large);
  }
}
.venue__left {
  border-bottom: var(--border-width) solid var(--text-color);
}
@media screen and (max-width: 1000px) {
  .venue__left {
    border-bottom: initial;
  }
}
.venue__header {
  display: flex;
  gap: var(--gap);
  width: 100%;
  justify-content: space-between;
  font-weight: 700;
}
.venue__contents {
  margin-top: var(--large);
}
.venue__question {
  font-weight: 700;
  padding-bottom: 0.5em;
}
.venue__answer {
  margin-bottom: var(--medium);
}
.venue .pill {
  display: inline-block;
  padding-left: 1em;
  padding-right: 1em;
}
.venue-item__image img,
.venue__answer-image img {
  border-radius: 6px;
}
.venue__answer-image {
  margin-top: 1em;
}
.venue__map-link {
  min-width: 150px;
}
/* CONTACT
---------------------------------------------- */
.contact-items {
  margin-top: var(--secondary-top-page-offset);
}
@media screen and (max-width: 1000px) {
  .contact-items {
    margin-top: var(--mobile-content-offset);
  }
}
.contact-items__section {
  margin-bottom: var(--large);
}
.contact-items__heading {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: var(--small);
}
.contact-items__social-links {
  font-size: var(--secondary-small-font-size);
  font-family: var(--secondary-font);
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
}

/* DONATE
---------------------------------------------- */
.donate-items {
  margin-top: var(--secondary-top-page-offset);
}
@media screen and (max-width: 1000px) {
  .donate-items {
    margin-top: var(--mobile-content-offset);
  }
}
.donate-items__section {
  margin-bottom: var(--extra-large);
}
.donate-items__heading {
  font-size: var(--secondary-medium-font-size);
  margin-bottom: var(--medium);
}
.donate-items__subheading {
  max-width: 337px;
}

.donate-box {
  background-color: var(--primary-accent-background-color);
  color: var(--text-accent-color);
  padding: var(--lr-page-padding);
  border-radius: 9px;
}
.donate-box .ft_pricing input[type="text"] {
  background: transparent !important;
  border: var(--border-width) solid var(--text-accent-color) !important;
  border-radius: 6px !important;
  color: var(--text-accent-color) !important;
  padding: 0 4px !important;
}
.donate-box .ft_pricing input[type="text"]::placehoder,
.donate-box .ft_pricing input[type="text"]:-moz-placeholder,
.donate-box .ft_pricing input[type="text"]::-moz-placeholder {
  color: var(--text-accent-color) !important;
}
@media screen and (max-width: 1000px) {
  .donate-box .ft_pricing input[type="text"] {
    margin: var(--small) calc(var(--gap) / 2) 0 0 !important;
  }
}
/* VOLUNTEERS
---------------------------------------------- */
.volunteers-items {
  margin-top: var(--secondary-top-page-offset);
}
@media screen and (max-width: 1000px) {
  .volunteers-items {
    margin-top: var(--mobile-content-offset);
  }
}
.volunteers-items__section {
  margin-bottom: var(--large);
}
.volunteers-items__section .pill {
  display: block;
}

.volunteers-items__heading {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: var(--small);
}

/* FESTIVAL INFO
---------------------------------------------- */
.festival-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--gap);
  margin-top: var(--secondary-top-page-offset);
}
@media (max-width: 1000px) {
  .festival-info {
    grid-template-columns: 1fr;
    gap: var(--lr-page-padding);
    margin-top: var(--mobile-content-offset);
  }
}

.info-card {
  padding: var(--lr-page-padding);
  border-radius: 9px;
  color: var(--text-accent-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 334px;
  background-color: var(--secondary-background-color);
}
@media (max-width: 1000px) {
  .info-card {
    min-height: 225px;
  }
}

.info-card:nth-of-type(2) {
  background-color: var(--primary-accent-background-color);
}
.info-card:nth-of-type(3) {
  background-color: var(--text-color);
}
.info-card:nth-of-type(4) {
  background-color: var(--primary-accent-background-color);
}
.info-card:nth-of-type(5) {
  background-color: transparent;
  border: var(--border-width) solid var(--text-color);
  color: var(--text-color);
}

.info-card__link {
  margin-top: var(--medium);
  font-weight: 700;
}
/* ABOUT THE FESTIVAL
---------------------------------------------- */
.about-page {
  margin-top: var(--secondary-top-page-offset);
}
@media screen and (max-width: 1000px) {
  .about-page {
    margin-top: var(--mobile-content-offset);
  }
}
.about__text-wrapper {
  background-color: var(--secondary-background-color);
  color: var(--text-accent-color);
  padding: var(--lr-page-padding);
  border-radius: 9px;
  margin-top: var(--extra-large);
  margin-bottom: var(--extra-large);
  max-width: 700px;
}
.about-text {
  max-width: 700px;
}
.partners__heading {
  font-size: var(--secondary-medium-font-size);
  margin-bottom: var(--extra-large);
}
.partner-category__title {
  font-weight: 700;
  font-size: var(--small-font-size);
  margin-bottom: var(--large);
}
.partners__grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--large-gap);
}
.partner-category__logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--large-gap);
}
.partner-category__logo img {
  width: 150px;
  height: 60px;
  object-fit: contain;
}

/* TICKETING
---------------------------------------------- */
.ticketing-items {
  margin-top: var(--secondary-top-page-offset);
}
@media screen and (max-width: 1000px) {
  .ticketing-items {
    margin-top: var(--mobile-content-offset);
  }
}
.ticketing-items__section {
  margin-bottom: var(--extra-large);
}
.ticketing-items__heading {
  font-weight: 700;
  margin-bottom: var(--medium);
}
.ticketing-item:first-of-type {
  border-top: var(--border-width) solid var(--text-color);
}
.ticketing-item {
  display: flex;
  justify-content: space-between;
  padding: var(--extra-small) 0;
  border-bottom: var(--border-width) solid var(--text-color);
}
.ticketing-items__footer-text {
  margin-top: var(--medium);
}
.single-ticket-cta {
  background-color: var(--secondary-background-color);
  color: var(--text-accent-color);
  border-radius: 9px;
  padding: var(--lr-page-padding);
  min-height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 550px;
  margin-bottom: var(--extra-large);
}
.single-ticket__byline {
  margin-top: var(--small);
}
.single-ticket-cta__button {
  display: block;
  background-color: var(--text-accent-color);
  color: var(--secondary-background-color);
  border: none;
}

/* MULTIPASSES
---------------------------------------------- */
.multipasses-content {
  margin-top: var(--secondary-top-page-offset);
}
@media screen and (max-width: 1000px) {
  .multipasses-content {
    margin-top: var(--mobile-content-offset);
  }
}
.multipasses__heading {
  font-weight: 700;
  margin-bottom: var(--medium);
}
.multipasses__text {
  margin-bottom: var(--extra-large);
}
.multipass-ctas {
  margin-bottom: var(--extra-large);
  display: flex;
  gap: var(--gap);
}
@media screen and (max-width: 1000px) {
  .multipass-ctas {
    display: grid;
    grid-template-columns: 1fr;
  }
}
.multipass-cta {
  background-color: var(--secondary-accent-background-color);
  border-radius: 9px;
  padding: var(--lr-page-padding);
  min-height: 322px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 1000px) {
  .multipass-cta {
    min-height: 250px;
  }
}
.multipass-cta:last-of-type {
  background-color: var(--secondary-accent-background-color);
  background-color: var(--primary-accent-background-color);
}
.multipass-cta__byline {
  margin-top: var(--extra-small);
  margin-bottom: var(--extra-large);
  font-size: var(--secondary-extra-small-font-size);
}
@media screen and (max-width: 1000px) {
  .multipass-cta__byline {
    margin-top: var(--extra-small);
    margin-bottom: var(--extra-large);
    font-size: var(--secondary-small-font-size);
  }
}
.multipass-cta__price {
  font-size: var(--secondary-medium-font-size);
  margin-bottom: var(--medium);
  justify-self: end;
}
.multipass-ctas .ft_button {
  display: block !important;
  background-color: var(--text-accent-color) !important;
  color: var(--text-color) !important;
  border: none !important;
  width: 100% !important;
}

.multipass-ctas .ft_ep_ft {
  position: absolute !important;
  bottom: var(--lr-page-padding) !important;
}
.multipass-ctas #ft_pricingAdditionalMessage {
  padding-left: var(--huge) !important;
  text-align: left !important;
}
@media screen and (max-width: 1000px) {
  .multipass-ctas #ft_pricingAdditionalMessage {
    padding-left: 60px !important;
  }
}
/* PROGRAM
---------------------------------------------- */
@media only screen and (max-width: 1000px) {
  .program-template .sidebar {
    flex-direction: column;
  }
  .program-template .sidebar__title {
    margin-bottom: var(--extra-large);
  }
  .program-template .sidebar__nav {
    flex-direction: column;
    overflow: initial;
    white-space: initial;
    width: 100%;
    border-top: initial;
    border-bottom: initial;
    display: flex;
    gap: var(--small);
    margin-bottom: initial;
  }
}
.program {
  margin-top: var(--secondary-top-page-offset);
  margin-bottom: 200px;
  border-top: var(--border-width) solid var(--text-color);
}
@media only screen and (max-width: 1000px) {
  .program {
    margin-bottom: initial;
    margin-top: initial;
    border-top: initial;
  }
}
.program-filter {
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border: none;
  min-inline-size: min-content;
}
@media (max-width: 1000px) {
  .program-filter {
    display: flex;
  }
}
.program-filter__heading {
  margin-bottom: var(--small);
}
.program-filter:not(:first-of-type) {
  margin-top: var(--large);
}
@media (max-width: 1000px) {
  .program-filter:not(:first-of-type) {
    margin-top: var(--small);
  }
}
@media (max-width: 1000px) {
  .program-filter__buttons {
    width: calc(100vw - calc(var(--lr-page-padding) * 2));
    overflow: auto;
    white-space: nowrap;
    border-top: var(--border-width) solid var(--text-color);
    border-bottom: var(--border-width) solid var(--text-color);
    display: flex;
    gap: var(--large);
    margin-bottom: initial;
  }
}
.program-filter__button {
  display: block;
}
@media (max-width: 1000px) {
  .program-filter__button {
    padding: var(--medium) 0;
    height: initial;
  }
}
.program-filter__button::before {
  display: inline-block;
  content: "";
  height: 0.6em;
  width: 0.6em;
  border-radius: 50%;
  border: var(--border-width) solid var(--text-color);
  margin-right: 0.8em;
}
@media (max-width: 1000px) {
  .program-filter__button::before {
    margin-right: 0.4em;
  }
}
.program-filter__button.active::before {
  background-color: var(--text-color);
  transition: background-color 0.4s ease-out;
}
@media (hover: hover) {
  .program-filter__button:hover::before {
    background-color: var(--text-color);
  }
}
.event-listing {
  display: grid;
  grid-template-columns: 1.6fr 1.6fr 0.8fr 0.6fr;
  gap: calc(var(--gap) * 1.5);
  border-bottom: var(--border-width) solid var(--text-color);
  padding: var(--lr-page-padding) 0;
}
@media only screen and (max-width: 1000px) {
  .event-listing {
    grid-template-columns: 1fr;
    padding: var(--extra-large) 0;
  }
}
.event-listing__column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 280px;
}
@media only screen and (max-width: 1000px) {
  .event-listing__column {
    min-height: initial;
  }
}
.event-listing__column-1 {
  flex-direction: column;
}
.event-listing__column-4 {
  align-items: end;
}
.event-listing__column-1 .event-listing__column-bottom {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}
.event-listing__column-1
  .event-listing__column-bottom
  .event-listing__play-wrapper {
  min-width: 75px;
}
@media only screen and (max-width: 1000px) {
  .event-listing__column-1 {
    order: 2;
  }
  .event-listing__column-1 .event-listing__column-bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--lr-page-padding);
  }
  .event-listing__column-1 .event-listing__column-bottom > *,
  .event-listing__column-1 .event-listing__column-bottom > div > a {
    display: block;
    width: 100%;
  }
  .event-listing__column-1 .event-listing__column-bottom .pill {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
  }
  .event-listing__column-1 .event-listing__play-wrapper {
    order: 2;
  }
}

.event-listing__image img {
  border-radius: 6px;
  margin-top: 1rem;
}
@media only screen and (max-width: 1000px) {
  .event-listing__image {
    margin: var(--medium) 0;
  }
}
.event-listing__title {
  font-size: var(--secondary-medium-font-size);
}
.event-listing__link {
  display: inline-block;
}
.clear-filters-wrapper {
  display: none; /* Hidden by default */
  margin-top: var(--large);
  margin-bottom: 480px;
}
@media only screen and (max-width: 1000px) {
  .clear-filters-wrapper {
    margin-bottom: 280px;
  }
}
.clear-filters-button {
  margin-left: 1em;
}
@media only screen and (max-width: 1000px) {
  .clear-filters-button {
    margin-left: 0em;
    margin-top: 1em;
  }
}
@media only screen and (min-width: 1001px) {
  .event-listing__excerpt {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 1000px) {
  .event-listing__excerpt {
    margin-bottom: var(--medium);
  }
}

/* SCREENING
---------------------------------------------- */
.screening-items {
  padding-top: var(--top-page-offset);
  /* Masonry container */
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: var(--large-gap);
  -moz-column-gap: var(--large-gap);
  column-gap: var(--large-gap);
  -moz-column-gap: var(--large-gap);
  -webkit-column-gap: var(--large-gap);
  column-gap: var(--large-gap);
}
@media only screen and (max-width: 1000px) {
  .screening-items {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}
.screening-item {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

@media only screen and (min-width: 1001px) {
  .screening-subheading,
  .screening-premiere,
  .screening-type {
    display: inline-block;
  }
  .screening-type--margin-left,
  .screening-premiere--margin-left {
    margin-left: var(--medium);
  }
}
.screening-subheading__item {
  display: inline-block;
  margin-top: var(--small);
}
.screening-premiere__container {
  gap: 5px;
}
.screening-premiere__container svg {
  vertical-align: bottom;
  display: inline-block;
}
@media only screen and (min-width: 1001px) {
  .screening-buy-button {
    position: absolute;
    right: var(--lr-page-padding);
    top: var(--top-page-offset);
  }
}
@media only screen and (max-width: 1000px) {
  .screening-buy-button {
    display: block;
    margin-top: var(--large);
  }
}
.screening-title {
  text-wrap: pretty;
}
.screening-play-trailer {
  position: absolute;
  right: 230px;
  top: var(--top-page-offset);
}
@media only screen and (max-width: 1000px) {
  .screening-play-trailer {
    right: var(--lr-page-padding);
    top: initial;
    bottom: var(--lr-page-padding);
    color: var(--text-accent-color);
    border-color: var(--text-accent-color);
  }
}
.screening-description {
  margin-top: var(--extra-large);
}
.screening-carousel .splide__arrow,
.product-carousel .splide__arrow {
  background: var(--primary-background-color);
}
.screening-carousel .splide__arrow path,
.product-carousel .splide__arrow path {
  fill: var(--text-color);
}
.screening-carousel .splide__pagination__page,
.product-carousel .splide__pagination__page {
  background: var(--primary-background-color);
}
.screening-makers {
  border-top: var(--border-width) solid var(--text-color);
  border-bottom: var(--border-width) solid var(--text-color);
  padding: var(--small) 0;
  margin: var(--extra-large) 0;
  display: flex;
  flex-direction: column;
  gap: var(--extra-small);
}
.short .screening-makers {
  margin-bottom: var(--extra-small);
}
.screening-maker {
  display: grid;
  grid-template-columns: 150px 1fr;
}
.screening-maker__title {
  font-weight: 700;
}
.screening-maker__name:not(:last-of-type)::after {
  content: ",\00a0";
  display: inline-block;
}
.screening-maker__name:nth-last-child(-n + 2):not(:last-of-type)::after {
  content: "\00a0&\00a0";
  display: inline-block;
}
.screening-maker__names--vertical {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.short {
  margin-top: var(--extra-large);
  background-color: var(--primary-light-background-color);
  padding: var(--lr-page-padding);
  border-radius: 9px;
}
.short__top {
  display: flex;
  /* gap: var(--gap); */
}
.short__image img {
  width: 150px;
  height: auto;
  border-radius: 6px;
  margin-right: var(--lr-page-padding);
}
@media only screen and (max-width: 600px) {
  .short__top {
    display: block;
  }
  .short__image img {
    max-width: 150px;
    margin-right: initial;
    margin-bottom: var(--lr-page-padding);
  }
}
.short__header {
  font-weight: 700;
  margin-bottom: var(--extra-small);
}
.screening-title__short {
  font-size: var(--secondary-extra-small-font-size);
}
.screening-item__c > div {
  padding-top: calc(var(--extra-large) * 2);
}
@media only screen and (max-width: 1000px) {
  .mobile-screening-image-container {
    position: relative;
    margin-bottom: var(--large);
  }
}
.screening-image img {
  border-radius: 9px;
}
.screening-widget-container {
  margin-top: var(--large);
}
/* Ferve */
.ft_ep_buttonRow button,
button.ft_button {
  padding: 10px 20px;
  -webkit-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
  -border-radius: 30px !important;
  border-radius: 30px !important;
  text-decoration: none;
  display: inline-block;
  height: 50px;
  box-sizing: border-box;
  vertical-align: middle;
  line-height: 30px;
}

.ft_ep_buttonRow button:hover,
button.ft_button:hover {
  background: #000;
  color: #fff;
}

.ft_ep_buttonRow button:hover svg path,
button.ft_button:hover svg path {
  stroke: #fff;
}

.ft_ep_buttonRow button svg,
button.ft_button svg {
  height: 30px;
  margin-left: 25px;
  float: right;
}

.ft_ep_buttonRow button svg path,
button.ft_button svg path {
  stroke: #000;
}

.ft_ed_headerRow {
  height: 24px !important;
  background: transparent !important;
  color: var(--text-color) !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  clear: both !important;
  font-family: var(--primary-font) !important;
  font-size: var(--primary-font-size) !important;
  border-bottom: var(--border-width) solid var(--text-color) !important;
}

.ft_ed_timeRowSelected {
  background: transparent !important;
}

.ft_ed_timeRow,
.ft_ed_timeSlotRow {
  background: transparent !important;
  border-top: none !important;
}

.ft_ed_wishlist {
  opacity: 0 !important;
}

.ft_ep_headerRow,
.ft_pricing th {
  height: 24px !important;
  background: transparent !important;
  color: var(--text-color) !important;
  font-family: var(--primary-font) !important;
  font-size: var(--primary-font-size) !important;
  border-bottom: var(--border-width) solid var(--text-color) !important;
}

.ft_ep_priceRow {
  border-top: none !important;
  padding-top: 8px !important;
  padding-bottom: 12px !important;
}
.ft_ep_priceRow:not(:first-of-type) {
  border-bottom: var(--border-width) solid var(--text-color) !important;
}
.ft_ep_priceRow div {
  font-family: var(--primary-font) !important;
}
@media only screen and (max-width: 1000px) {
  .ft_ep_priceRow div {
    line-height: initial !important;
  }
}
.ft_ep_qty {
  padding-left: initial !important;
}

.ft_ed_headerRow,
.ft_ed_timeRow,
.ft_ed_timeSlotRow {
  padding-left: initial !important;
}

.ft_ep_qty select {
  background: var(--text-accent-color) !important;
  border: none !important;
}

.ft_ep_buttonRow {
  margin-top: var(--medium) !important;
  padding-top: initial !important;
  border-top: initial !important;
}

.screening-widget-container .ft_ep_buttonRow {
  margin-top: var(--extra-large) !important;
}

button.ft_button {
  padding: 0.8em 4em !important;
  width: initial !important;
  height: initial !important;
  border: none;
  margin-top: initial !important;
  color: var(--text-accent-color) !important;
  text-align: center;
  font-weight: 700 !important;
  text-transform: uppercase;
  font-family: var(--primary-font) !important;
  font-size: var(--primary-font-size) !important;
  background-color: var(--primary-accent-background-color) !important;
  border-radius: 9px !important;
  line-height: initial !important;
  transition: background-color 0.4s ease-out;
}
@media (hover: hover) {
  button.ft_button:hover {
    color: var(--text-accent-color) !important;
    background-color: var(--text-color) !important;
  }
}
.ft_timeout,
#ft_pricingAdditionalMessage {
  color: var(--text-color) !important;
  opacity: 70%;
}
#ft_pricingAdditionalMessage {
  text-align: right;
}
#ft_ferveCart,
#ft_ferveCartView {
  background-color: var(--primary-background-color) !important;
  border: none !important;
}
#ft_fcButtons .ft_button {
  padding: 0 !important;
}
#ft_fcTitle {
  text-shadow: initial !important;
}

.donate-box .ft_pricing {
  margin-top: 100px !important;
}

.donate-box .ft_radio input[type="radio"] + label,
.donate-box .ft_radio input[type="checkbox"] + label {
  display: inline-block;
  margin: var(--small) calc(var(--gap) / 2) 0 0 !important;
  padding: 7px 20px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-shadow: initial !important;
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent !important;
  background-image: initial !important;
  background-image: initial !important;
  background-image: initial !important;
  background-image: initial !important;
  background-image: initial !important;
  background-repeat: initial !important;
  border-color: initial !important;
  border-color: initial !important;
  border: var(--border-width) solid var(--text-accent-color) !important;
  border-bottom-color: initial !important;
  filter: initial !important;
  filter: initial !important;
  -webkit-box-shadow: initial !important;
  -moz-box-shadow: initial !important;
  box-shadow: initial !important;
  font-weight: 700;
  color: var(--text-accent-color);
  border-radius: 6px !important;
  transition: color 0.4s ease-out, background-color 0.4s ease-out;
}

.donate-box .ft_radio input[type="radio"]:checked + label,
.donate-box .ft_radio input[type="checkbox"]:checked + label {
  background-color: var(--text-accent-color) !important;
  color: var(--primary-accent-background-color) !important;
}

@media (hover: hover) {
  .donate-box .ft_radio input[type="radio"]:hover + label,
  .donate-box .ft_radio input[type="checkbox"]:hover + label {
    background-color: var(--text-accent-color) !important;
    color: var(--primary-accent-background-color) !important;
  }
}

.donate-box button.ft_button {
  color: var(--primary-accent-background-color) !important;
  background-color: var(--text-accent-color) !important;
  border: var(--border-width) solid var(--text-accent-color) !important;
}
@media (hover: hover) {
  .donate-box button.ft_button:hover {
    color: var(--text-accent-color) !important;
    background-color: transparent !important;
    border: var(--border-width) solid var(--text-accent-color) !important;
  }
}
.ft_fcItemThumbnail {
  border: initial !important;
  border-radius: 3px !important;
}
#ft_ferveCart a,
#ft_ferveCartView a {
  color: var(--text-color) !important;
}

#ft_codesContainer .ft_ep_priceRow:not(:first-of-type),
#ft_codesContainer .ft_ep_priceRow {
  border-bottom: initial !important;
  padding-bottom: 0px !important;
  min-height: initial !important;
  line-height: initial !important;
}

#ft_codesContainer button.ft_button {
  padding: 0.4em 1em !important;
  background-color: var(--text-color) !important;
  border-radius: 6px !important;
  border: var(--border-width) solid var(--text-color) !important;
  font-size: var(--small-font-size) !important;
}
#ft_codesContainer .ft_ep_text,
.ft_ep_subtitle {
  padding-left: 0px !important;
}

#ft_codesContainer input[type="text"] {
  background-color: transparent !important;
  border: var(--border-width) solid var(--text-color) !important;
  border-radius: 6px !important;
  padding: 0.4em 0.2em !important;
  font-size: var(--small-font-size) !important;
  line-height: initial !important;
}
#ft_ferveCart button.ft_button {
  font-size: var(--small-font-size) !important;
}
.ft_ed_cal_times .ft_ed_dateTime {
  width: initial !important;
}
.ft_ed_cal_key {
  background: var(--text-accent-color) !important;
  padding: 4px !important;
  border-radius: 3px !important;
  margin-bottom: var(--small) !important;
}
.ft_ed_cal_header {
  background: var(--primary-accent-background-color) !important;
}
/* OVERLAY
---------------------------------------------- */
.overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background: var(--text-color);
  color: var(--text-accent-color);
  visibility: hidden;
}
.overlay.active {
  visibility: visible;
  z-index: 9;
}
.overlay-menu {
  padding: 0 var(--lr-page-padding);
  padding-top: calc(var(--top-page-offset) * 1.5);
  padding-bottom: var(--extra-large);
  height: 100%;
}
.overlay-menu__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.overlay-menu__large-button a {
  padding: var(--small);
  display: block;
}
.overlay-menu__large-button:not(:last-of-type) {
  border-bottom: var(--border-width) solid var(--text-accent-color);
}
.overlay-menu__bottom {
  display: flex;
  gap: var(--gap);
}
.overlay-menu__bottom a {
  display: flex;
  gap: 5px;
}

/* VIDEO MODAL
---------------------------------------------- */
.video-modal,
.video-modal .video-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3000;
}
.video-modal {
  overflow: hidden;
  position: fixed;
  opacity: 0;

  -webkit-transform: translate(500%, 0%);
  transform: translate(500%, 0%);

  -webkit-transition: -webkit-transform 0s linear 0s;
  transition: transform 0s linear 0s;

  /* using flexbox for vertical centering */

  /* Flexbox display */
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;

  /* Vertical alignment */
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.video-modal .video-overlay {
  z-index: 0;
  background: rgba(28, 28, 28, 0.82); /* overlay color */

  opacity: 0;

  -webkit-transition: opacity 0.2s ease-out 0.05s;
  transition: opacity 0.2s ease-out 0.05s;
}

.video-modal-content {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;

  margin: 0 auto;

  overflow-y: visible;

  background: #000;

  width: calc(100% - 12em);
  height: 0;
  padding-top: calc((100% - 12em) * 0.5625); /* 16:9 calc */
}

/* Scaling to fit within the current Viewport size:
   When viewport aspect ratio is greater than 16:9
   work off the height instead of the width for calc */
@media (min-aspect-ratio: 16/9) {
  .video-modal-content {
    width: 0;
    height: calc(100vh - 10em);
    padding-top: 0;
    padding-left: calc((100vh - 10em) * 1.7778); /* 16:9 calc */
  }
}

/* Mobile Layout Tweaks - side margins reduced */
@media (max-width: 640px) {
  .video-modal-content {
    width: calc(100% - 1em);
    padding-top: calc((100% - 1em) * 0.5625); /* 16:9 calc */
  }
}

/* modal close button */
.close-video-modal {
  display: block;
  position: absolute;
  left: 0;
  top: -40px;

  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  color: var(--text-accent-color);
}

/* set the iframe element to stretch to fit its parent element */
iframe#youtube {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  background: #000;
  /* box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.5); */
}

/* show the modal: 
   add class to the body to reveal */
.show-video-modal .video-modal {
  opacity: 1;

  transform: translate(0%, 0%);
  -webkit-transform: translate(0%, 0%);
}
.show-video-modal .video-modal .video-overlay {
  opacity: 1;
}
.show-video-modal .video-modal-content {
  transform: translate(0%, 0%);
  -webkit-transform: translate(0%, 0%);
}
