/* Variables
---------------------------------------------- */
:root {
  --vh: 1;

  --small-font-size: 13px;

  --secondary-large-font-size: 50px;
  --secondary-medium-font-size: 35px;
  --secondary-small-font-size: 30px;
  --secondary-extra-small-font-size: 25px;

  --lr-page-padding: 22px;
  --top-page-padding: 13px;
  --top-page-offset: 92px;
  --secondary-top-page-offset: calc(52px + var(--top-page-offset));
  --navbar-height: 68px; /* navbar height = 58px on mobile */
  --mobile-content-offset: 40px;
  --page-bottom: 80px;

  --gap: 28px;
  --large-gap: 42px;

  --extra-small: 6px;
  --small: 10px;
  --medium: 20px;
  --large: 25px;
  --extra-large: 40px;
  --huge: 70px;

  --sidebar-width: 370px;

  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }

  /* Variables for-small-tablet-below
  ---------------------------------------------- */
  @media (max-width: 1000px) {
    --secondary-large-font-size: 30px;
    --secondary-medium-font-size: 24px;
    --secondary-small-font-size: 18px;
    --secondary-extra-small-font-size: 16px;

    --lr-page-padding: 10px;
    --top-page-padding: 10px;
    --top-page-offset: 92px;
    --secondary-top-page-offset: calc(52px + var(--top-page-offset));
    --page-bottom: 50px;

    --gap: var(--lr-page-padding);
    --large-gap: 20px;

    --extra-small: 5px;
    --small: 8px;
    --medium: 15px;
    --large: 20px;
    --extra-large: 30px;
    --huge: 45px;
  }
}
